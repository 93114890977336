<template>
  <div>
    <h1 class="heading">Partner Users</h1>
    <div style="display: flex;margin-bottom: 15px;justify-content: flex-end">
      <v-btn outlined small color="primary" @click="createUserOpen">
        Create user
      </v-btn>
    </div>
    <div style="margin-bottom: 15px">
      <v-data-table
          :headers="usersHeader"
          :items="users"
          class="elevation-1"
          hide-default-footer
      >
        <template v-slot:body>

          <tbody v-if='users.length'>
          <tr v-for="(item, index) in users" :key="index">
            <td class="d-block d-sm-table-cell" style="display: flex;align-items: center;justify-content: left"><v-icon v-if="item.is_blocked">mdi-lock</v-icon>{{ item.name }}</td>
            <td class="d-block d-sm-table-cell">{{ item.email }}</td>
            <td class="d-block d-sm-table-cell">{{ countOfProjects(item.id) }}</td>
            <td class="d-block d-sm-table-cell">{{ $moment(item.created_at*1000).format('DD.MM.YYYY HH:mm') }}</td>
            <td class="d-block d-sm-table-cell">
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="loginAsOwner(item.id)"  v-if="item.email_verified_at">
                    <v-list-item-title
                    >Login as</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item  link v-if="!item.email_verified_at" @click="verifyUser(item.id)">
                    <v-list-item-title style="cursor: pointer"
                    >Verify</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="toggleBlock(item.id)">
                    <v-list-item-title style="cursor: pointer"
                    >{{item.is_blocked?'Unblock':'Block'}}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="openEditUser(item)">
                    <v-list-item-title style="cursor: pointer"
                    >Edit</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="deleteUserOpen(item.id)">
                    <v-list-item-title style="cursor: pointer;color:red;"
                    >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td style="text-align: center" colspan="5">You don't have users yet, it's time to create them</td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile"
        v-model="dialogUser"
        max-width="500px"
        id="popup-registration1"
        class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="dialogUser = false"
        >mdi-close</v-icon
        >
        <v-form
            class="form"
            style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
            ref="form_add_user"
            @submit="registerUserConfirm"
            v-model="valid_register"
            novalidate
        >
          <h2 class="popup__title" style="margin-bottom: 15px;">Register user</h2>
          <div class="form__item form__item_error">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="text"
                  :placeholder="$t('landing.popup[\'Ваше имя\']')"
                  v-model="registerUser.name"
                  :rules="nameRules"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div class="form__item">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="email"
                  placeholder="E-mail"
                  v-model="registerUser.email"
                  :rules="emailRules"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div class="form__item">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="password"
                  :placeholder="$t('landing.popup[\'Пароль\']')"
                  v-model="registerUser.password"
                  :rules="passwordRules"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div
              style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <div
                class="v-messages theme--light error--text"
                v-if="error_register"
                style="margin-bottom: 20px"
                role="alert"
            >
              <div class="v-messages__wrapper">
                <div class="v-messages__message" style="font-size: 14px">
                  {{ error_register_message }}
                </div>
              </div>
            </div>
            <v-btn color="primary"
                   outlined
                   small type="submit" class="btn_form">
              Create
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile"
        v-model="editUserModal"
        max-width="500px"
        id="popup-edit"
        class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="editUserModal = false"
        >mdi-close</v-icon
        >
        <v-form
            class="form"
            style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
            v-if="editUser"
            ref="form_edit_user"
            @submit="editUserConfirm"
            novalidate
        >
          <h2 class="popup__title" style="margin-bottom: 15px;">Edit user</h2>
          <div class="form__item form__item_error">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="text"
                  :placeholder="$t('landing.popup[\'Ваше имя\']')"
                  v-model="editUser.name"
                  :rules="nameRules"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div class="form__item">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="email"
                  placeholder="E-mail"
                  v-model="editUser.email"
                  :rules="emailRules"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div class="form__item">
            <div class="form__control">
              <v-text-field
                  solo
                  outlined
                  flat
                  type="phone"
                  placeholder="Phone"
                  v-model="editUser.phone"
                  required
                  validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div
              style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <v-btn color="primary"
                   outlined
                   small type="submit" class="btn_form">
              Edit
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" title="Success" :content="successMessage"/>
    <PopupDelete ref="PopupDelete" title="Are you sure about delete user?" @confirm="deleteUserConfirm"/>
  </div>
</template>

<script>
  import AgencyService from "../services/agency.service";
  import titleMixin from "../mixins/titleMixin";
  import {mapState} from "vuex";
  export default {
    mixins:[titleMixin],
    title(){
      return "Partner users";
    },
    name: "UsersPartners",
    components:{
      PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
      PopupDelete:() => import("@/components/Popup/PopupDelete"),
    },
    data(){
      return{
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: 'jpg,svg,webp,gif,png',
            images_upload_handler: async  (blobInfo, success, failure) => {
              let formData = new FormData();
              let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
              formData.append("lending_settings_id", this.$store.state.landing.settings_id);
              formData.append("image", _file);
              formData.append("file", _file);
              const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
              success(data);
            },
            plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 200,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

          setup:function(editor){
            editor.ui.registry.addButton('custom_button', {
              text: 'Add Button',
              onAction: function() {
                // Open a Dialog
                editor.windowManager.open({
                  title: 'Add custom button',
                  body: {
                    type: 'panel',
                    items: [{
                      type: 'input',
                      name: 'button_label',
                      label: 'Button label',
                      flex: true
                    },{
                      type: 'input',
                      name: 'button_href',
                      label: 'Button href',
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_target',
                      label: 'Target',
                      items: [
                        {text: 'None', value: ''},
                        {text: 'New window', value: '_blank'},
                        {text: 'Self', value: '_self'},
                        {text: 'Parent', value: '_parent'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_style',
                      label: 'Style',
                      items: [
                        {text: 'Success', value: 'success'},
                        {text: 'Info', value: 'info'},
                        {text: 'Warning', value: 'warning'},
                        {text: 'Error', value: 'error'}
                      ],
                      flex: true
                    }]
                  },
                  onSubmit: function (api) {

                    var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                    // insert markup
                    editor.insertContent(html);

                    // close the dialog
                    api.close();
                  },
                  buttons: [
                    {
                      text: 'Close',
                      type: 'cancel',
                      onclick: 'close'
                    },
                    {
                      text: 'Insert',
                      type: 'submit',
                      primary: true,
                      enabled: false
                    }
                  ]
                });
              }
            });
          },
          content_css: ["/custom_tinymce.css"],
        },
        cloneHotelModal:false,
        clonedUserID:null,
        clonedHotelID:null,
        payments:[],
        tariffNumeric:{
          1:'Free',
          2:'Standart',
          3:'Pro'
        },
        registerUser: {
          name: null,
          email: null,
          password: null,
          is_agency:0,
        },
        editUserModal:false,
        editUser:null,
        dialogUser:false,
        nameRules: [(v) => !!v || this.$t('landing.valid["Имя обязателено"]')],
        passwordRules: [
          (v) => !!v || this.$t('landing.valid["Пароль обязателен"]'),
          (v) =>
            (v && v.length >= 6) ||
            this.$t(
              'landing.valid["Пароль должен содержать не менее 6 символов"]'
            ),
          (v) =>
            (v && v.length < 30) ||
            this.$t("form['Пароль должен содержать не более 30 символов']"),
        ],
        emailRules: [
          (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('landing.valid["E-mail должен быть валидным"]'),
        ],
        users:[],
        hotels:[],
        valid_register: true,
        usersHeader:[
          { text: "Name", align: "left", sortable: true },
          { text: "Email", align: "left", sortable: true },
          { text: "Count of projects", align: "left", sortable: true },
          { text: "Registration date", align: "left", sortable: true },
          { text: "Options", align: "left" },
        ],
        error_register_message:'',
        error_register:false,
        successMessage:"",
        deleteUserID:null,
        deleteHotelID:null,
        editHotelModal:false,
        usersFiltered:[],
        availableSlots:[],
        error_clone:false,
        error_clone_message:"",
        allSlotsCount:[]
      }
    },
    async mounted() {
      this.users = await AgencyService.getUsers();
      this.hotels = await AgencyService.getHotels();
    },
    computed:{
      ...mapState(['settings']),
    },
    methods:{
      async verifyUser(user_id){
        const data = await AgencyService.verifyUser(user_id);
        const candidate = this.users.find(x => x.id === data.id);
        if(candidate){
          const candidateIndex = this.users.indexOf(candidate);
          if(candidateIndex>=0){
            this.users.splice(candidateIndex,1,data);
          }
        }
      },
      async saveSettings(){
        await AgencyService.putAgencySettings(this.settings);
      },
      countOfProjects(user_id){
        return this.hotels.filter(x => x.owner_id === user_id).length;
      },
      async toggleBlock(user_id){
        const data = await AgencyService.toggleBlockUser(user_id);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1,data);
            }
          }
          this.successMessage = `User ${data.name} ${data.is_blocked?'blocked':'unblocked'} successfully!`;
          this.$refs.PopupSuccess.open();
        }

      },
      async deleteUserConfirm(){
        const data = await AgencyService.deleteOwner(this.deleteUserID);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1);
            }
          }
        }
      },
      deleteUserOpen(user_id){
        this.deleteUserID = user_id;
        this.$refs.PopupDelete.open();
      },
      async loginAsOwner(user_id){
        const data = await AgencyService.loginAsOwner(user_id);
        localStorage.removeItem('token');
        window.open(`${process.env.VUE_APP_LINK}/?admin_token=${data.agency_token}`,'_self');
      },
      async editUserConfirm(e){
        e.preventDefault();
        const data = await AgencyService.editOwner(this.editUser);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1,data);
            }
          }
        }
        this.successMessage = "User edited successfuly!";
        this.editUserModal = false;
        this.$refs.PopupSuccess.open();
      },
      openEditUser(user){
        this.editUser = JSON.parse(JSON.stringify(user));
        this.editUserModal = true;
      },
      async registerUserConfirm(e){
        this.error_register = false;
        this.error_register_message = "";
        e.preventDefault();
        if (this.$refs.form_add_user.validate()) {
          const data = await AgencyService.postRegister(this.registerUser);
          if(data.id){
            this.users.push(data);
            this.successMessage = "User created successfully!";
            this.dialogUser = false;
            this.$refs.PopupSuccess.open()
          }else{
            this.error_register_message = data.message;
            this.error_register = true;
          }
        }else{
          // this.va
        }
      },
      createUserOpen(){
        this.register = {
          name: null,
          email: null,
          password: null,
        };
        this.dialogUser = true;
      }
    }
  }
</script>

<style scoped>

</style>
